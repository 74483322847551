import "./info.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

import Submit, { SubmitType } from "../Components/Submit/Submit";
import { StateForm } from "../useForm";

export type PropsForm = PropsWithChildren<{
  handleSubmit(event: React.FormEvent<HTMLFormElement>): void;
  stateForm: StateForm;
  fromLanding: string;
  infoRequest: string;
}>;

export const InfoForm: React.FC<PropsForm> = (props: PropsForm) => {
  const infoSubmit: SubmitType = {
    textButtonSubmit: "Contactar",
    textButtonSubmitting: "Enviando ...",
    stateForm: props.stateForm,
    textInfo: props.infoRequest,
  };
  return (
    <form
      name="solicita-informacion"
      method="post"
      // data-netlify="true"
      onSubmit={(event) => {
        props.handleSubmit(event);
      }}
      className="m-info-form">
      <div className="m-info-form__grid">
        <label
          hidden
          htmlFor={"name"}>
          Nombre
        </label>
        <input
          className={"a-input"}
          type="text"
          name="name"
          placeholder="Nombre"
          required
        />
        <label hidden>Email </label>
        <input
          className={"a-input"}
          type="email"
          name="email"
          placeholder="Email"
          required
        />
        <label
          hidden
          htmlFor={"company"}>
          Empresa
        </label>
        <input
          className={"a-input"}
          type="text"
          name="company"
          placeholder="Empresa"
          required
        />
        <label hidden>Teléfono </label>
        <input
          className={"a-input"}
          type="phone"
          name="phone"
          placeholder="Teléfono"
          required
        />
      </div>
      <textarea
        className={"a-input a-info-form__text-area"}
        name={"info"}
        placeholder={"Información"}
      />
      <div className={"m-info-form__check-submit"}>
        <div className={"m-info-form__private"}>
          <input
            type="checkbox"
            name={`politica--${props.fromLanding}`}
            id={`politica--${props.fromLanding}`}
            value="acepta-politica"
            required
          />
          <label
            htmlFor={`politica--${props.fromLanding}`}
            className={"a-policy"}>
            He leído y acepto la{" "}
            <a
              href="/legal/politica-de-privacidad/"
              target="_blank"
              rel="nofollow noopener noreferrer">
              política de privacidad
            </a>{" "}
          </label>
        </div>
        <div className="center">
          <Submit submit={infoSubmit} />
        </div>
      </div>
    </form>
  );
};
