import * as React from "react";
// Remember that all const are suffix _svg

const phone_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.5"
    height="19.497"
    viewBox="0 0 19.5 19.497">
    <path
      d="M19.29,14.791c-.726-1.372-3.247-2.86-3.358-2.925a2,2,0,0,0-.978-.282,1.217,1.217,0,0,0-1.088.607,12.056,12.056,0,0,1-.937,1.036A1.558,1.558,0,0,1,10.654,13L6.5,8.847a1.564,1.564,0,0,1-.228-2.274,12.176,12.176,0,0,1,1.037-.938,1.2,1.2,0,0,0,.559-.721,1.787,1.787,0,0,0-.236-1.349C7.572,3.458,6.083.937,4.711.211a1.783,1.783,0,0,0-2.1.315L1.7,1.443A5.014,5.014,0,0,0,.137,6.331a10.338,10.338,0,0,0,2.994,4.724l5.316,5.316C10.524,18.448,12.5,19.5,14.332,19.5h0a5.206,5.206,0,0,0,3.725-1.7l.917-.917A1.774,1.774,0,0,0,19.29,14.791Z"
      transform="translate(0 -0.004)"
      fill="#59b1de"
    />
  </svg>
);
const fax_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.485"
    height="20.667"
    viewBox="0 0 18.485 20.667">
    <path
      d="M33.483,4.513V.689A.689.689,0,0,0,32.794,0H23.7a.689.689,0,0,0-.689.689V4.512H22.7V17.256h2.067v1.481a.552.552,0,0,1-.551.551h-4.34a.515.515,0,0,1-.551-.448v-.448h1.584A.689.689,0,0,0,21.6,17.7V4.064a.689.689,0,0,0-.689-.689H16.364a.689.689,0,0,0-.689.689V17.7a.689.689,0,0,0,.689.689h1.584v.448a1.881,1.881,0,0,0,1.929,1.826h4.34a1.931,1.931,0,0,0,1.929-1.929V17.257h7.325a.689.689,0,0,0,.689-.689V5.2A.689.689,0,0,0,33.483,4.513Zm-8.026,9.2H24.321a.551.551,0,0,1,0-1.1h1.137a.551.551,0,1,1,0,1.1Zm0-2.273H24.321a.551.551,0,0,1,0-1.1h1.137a.551.551,0,1,1,0,1.1Zm0-2.273H24.321a.551.551,0,0,1,0-1.1h1.137a.551.551,0,1,1,0,1.1Zm3.41,4.547H27.73a.551.551,0,1,1,0-1.1h1.137a.551.551,0,1,1,0,1.1Zm0-2.273H27.73a.551.551,0,1,1,0-1.1h1.137a.551.551,0,1,1,0,1.1Zm0-2.273H27.73a.551.551,0,1,1,0-1.1h1.137a.551.551,0,1,1,0,1.1ZM25.974,3.479a.551.551,0,0,1,0-1.1h4.547a.551.551,0,0,1,0,1.1H25.974Zm6.3,10.23H31.141a.551.551,0,0,1,0-1.1h1.137a.551.551,0,1,1,0,1.1Zm0-2.273H31.141a.551.551,0,0,1,0-1.1h1.137a.551.551,0,1,1,0,1.1Zm0-2.273H31.141a.551.551,0,0,1,0-1.1h1.137a.551.551,0,1,1,0,1.1Z"
      transform="translate(-15.675)"
      fill="#59b1de"
    />
  </svg>
);

const marker_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="24.43"
    viewBox="0 0 20 24.43">
    <path
      d="M31.044,0a10,10,0,0,0-10,10c0,5.523,10,14.43,10,14.43s10-8.907,10-14.43A10,10,0,0,0,31.044,0Zm0,14.527a4.81,4.81,0,1,1,4.81-4.81A4.811,4.811,0,0,1,31.044,14.527Z"
      transform="translate(-21.044)"
      fill="#59b1de"
    />
  </svg>
);

export { fax_svg, marker_svg, phone_svg };
