import "./modals.scss";

import * as React from "react";

import { close_svg, waveLeft_svg, waveRight_svg } from "./elements_modal";

function withModal(
  Component: React.FC<any>,
  props: any,
  control: { has_modal: boolean; toggleModal: any }
): React.ComponentClass {
  return class x extends React.Component {
    constructor(props: any) {
      super(props);
    }
    render() {
      return (
        <>
          <div className={`o-modal ${control.has_modal ? "" : "o-modal--hidden"}`}>
            <div className="m-modal__elements">
              {waveLeft_svg}
              {waveRight_svg}
            </div>
            <button
              className={"m-modal__close"}
              onClick={control.toggleModal}>
              {close_svg}
              <span> Cerrar </span>
            </button>
            <div className={"c-modal"}>
              <Component {...props} />
            </div>
          </div>
        </>
      );
    }
  };
}

export default withModal;
