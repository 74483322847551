import "../styles/layouts/contact.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import HeroContact from "../components/Hero/HeroContact/HeroContact";
import Layout from "../components/Layout/layout";
import { SEO } from "../components/SEO/SEO";
import SubscribeNewsletter from "../components/SubscribeNewsletter/SubscribeNewsletter";
import WeTalk from "../components/WeTalk/WeTalk";

const PageContact = ({ data }: PageProps<Queries.JoinTheTeamQuery>) => {
  const resume: Record<string, unknown> = {
    text: "Únete al equipo profesional de Grupo Usieto",
    _type: "span",
  };
  return (
    <Layout>
      <div className="l-contact">
        <HeroContact />
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default PageContact;

export const Head = () => <SEO title={"Contacta con nosotros"} />;

export const query = graphql`
  query Contacto {
    sanityPageJoinOurTeam {
      hero {
        title
        resume: _rawText
        photo {
          alt
          image {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
      }
      form {
        title
        subtitle
      }
    }
  }
`;
