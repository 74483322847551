import "./location-map.scss";

import * as React from "react";

const LocationMap: React.FC = () => {
  return (
    <div className={"m-location-map"}>
      <iframe
        className={"a-location-map"}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2981.4960708003728!2d-0.9822275845661844!3d41.645022779241344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd591a3fffffffff%3A0xd3faccb5d5eb88b!2sGrupo%20Usieto!5e0!3m2!1ses!2ses!4v1664781324100!5m2!1ses!2ses"
        width="1235"
        height="661"
        style={{ border: "0" }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
};

export default LocationMap;
