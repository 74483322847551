import "./we-talk.scss";

import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import { openOut_svg } from "../../assets/svg/controls_elements";
import { InfoForm } from "../Forms/Info/InfoForm";
import { TypeForm } from "../Forms/useForm";
import withForm from "../Forms/withForm";
import { useModal } from "../Modals/useModal";
import withModal from "../Modals/withModal";
import { fax_svg, marker_svg, phone_svg } from "./elements_weTalk";
import LocationMap from "./LocationMap/LocationMap";

const WeTalk: React.FC = () => {
  const InfoFormUse = withForm(InfoForm, TypeForm.CONTACT);
  const [has_modal, toggleModal] = useModal();

  const data: Queries.weTalkQuery = useStaticQuery(graphql`
    query weTalk {
      sanityDocumentModules {
        formWeTalk {
          title
        }
      }
      sanityDocumentData {
        legalName
        address {
          cp
          location
          street
          town
        }
        foodService {
          phone
        }
        logistics {
          phone
        }
        globals {
          phone
        }
      }
    }
  `);

  const MapOpen = withModal(
    LocationMap,
    {},
    {
      has_modal,
      toggleModal,
    }
  );

  return (
    <>
      <MapOpen />
      <section className={"o-we-talk"}>
        <div className={"c-we-talk"}>
          <div className="m-we-talk__form">
            <div className="m-we-talk__header">
              <h3>¿Hablamos?</h3>
              <p className={"m-we-talk__subtitle"}>Contacta con nosotros</p>
            </div>
            <InfoFormUse />
          </div>
          <div className="m-we-talk__map">
            <button
              onClick={() => toggleModal()}
              aria-label={"Ver mapa en tamaño completo"}
              className={"m-we-talk__open-map m-control m-control--sea"}>
              {openOut_svg}
            </button>
            <StaticImage
              imgClassName={"a-we-talk__photo"}
              className={"m-we-talk__photo"}
              src={"./img.png"}
              alt={"mapa"}
            />
          </div>
        </div>
        <div className="m-we-talk__footer">
          <address className="m-we-talk__address">
            {marker_svg}
            <span>
              <b className={"a-we-talk__legal-name"}>{data.sanityDocumentData?.legalName}: </b>
              {data.sanityDocumentData?.address?.location} {data.sanityDocumentData?.address?.street},{" "}
              {data.sanityDocumentData?.address?.cp}. {data.sanityDocumentData?.address?.town}
            </span>
          </address>
          <div className="m-we-talk__phones-n-fax">
            <div className="m-we-talk__phones">
              {phone_svg}
              <ul className={"m-we-talk__list-phones"}>
                <li>
                  <a
                    href={`tel:+34${data.sanityDocumentData?.foodService?.phone}`}
                    target={"_blank"}
                    rel={"nofollow noopener noreferrer"}>
                    {data.sanityDocumentData?.foodService?.phone}
                  </a>
                </li>
                <li>
                  <a
                    href={`tel:+34${data.sanityDocumentData?.logistics?.phone}`}
                    target={"_blank"}
                    rel={"nofollow noopener noreferrer"}>
                    {data.sanityDocumentData?.logistics?.phone}
                  </a>
                </li>
              </ul>
            </div>
            <span className={"m-we-talk__fax"}> {fax_svg} 976 471 037 </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default WeTalk;
