import "./hero-contact.scss";

import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import { isotype_svg } from "../../../assets/svg/logo_elements";
import RRSS from "../../RRSS/RRSS";
import { wave_svg } from "./elements_heroContact";

const HeroContact: React.FC = () => {
  const { sanityDocumentData: data }: Queries.HeroContactQuery = useStaticQuery(graphql`
    query HeroContact {
      sanityDocumentData {
        logistics {
          phone
        }
        foodService {
          phone
        }
        globals {
          phone
        }
        globals {
          email
        }
      }
    }
  `);

  return (
    <section className={"o-hero-contact"}>
      <div className="m-hero-contact__first-row-wrapper">
        <div className={"c-hero-contact"}>
          <div className="m-hero-contact__first-row">
            <div className="m-hero-contact__title">
              <h1 className={`a-hero-contact__title`}>Contacta con nosotros</h1>
            </div>
            <StaticImage
              className={"m-hero-contact__photo"}
              width={960}
              height={895}
              src={"../../../assets/images/contacto.png"}
              alt={"Peronsa tecleando"}
            />
          </div>
        </div>
      </div>
      <div className="m-hero-contact__second-row-wrapper">
        <div className="m-hero-contact__second-row">
          <div>
            <h2 className={"a-hero-service__subtitle"}>Estamos a tu disposición</h2>
            <div className="m-hero-contact__isotype">
              {" "}
              <StaticImage
                src={"../../assets/images/favicom.png"}
                alt={"Logotipo Grupo Usieto"}
              />
            </div>
            {wave_svg}
          </div>
          <div className="m-hero-contact__resume">
            <p>
              Consulta más información llamando al{" "}
              <a
                href={`tel:${data?.foodService?.phone}`}
                target={"_blank"}
                rel={"noopener nofollow noreferrer"}>
                {data?.foodService?.phone}
              </a>{" "}
              /{" "}
              <a
                href={`tel:${data?.logistics?.phone}`}
                target={"_blank"}
                rel={"noopener nofollow noreferrer"}>
                {data?.logistics?.phone}
              </a>{" "}
              o mandando un correo a{" "}
              <a
                href={`mailto:${data?.globals?.email}`}
                target={"_blank"}
                rel={"noopener nofollow noreferrer"}>
                {data?.globals?.email}
              </a>
            </p>
            <p>Síguenos en nuestras redes sociales.</p>
            <RRSS colorLogo={"light"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroContact;
